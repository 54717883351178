import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../components/floating-button/floating-button';
import Layout from '../../components/layout';
import React from 'react';

const PrivacyPolicyPage = (props) => {
    const components = props.data.cms.cookiesAndPrivacyPolicyPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout
            location={props.location}
            locale={props.pageContext.locale}
            linksColor={`black`}
        >
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    )
}
export default PrivacyPolicyPage;

export const query = graphql`
query ($locale: String!){
    cms {
      cookiesAndPrivacyPolicyPageTemplate(locale: $locale) {
        components {
          ... on STRAPI_ComponentComponentsCookiesAndPrivacyPolicy {
            __typename
            title
            paragraph {
              listStyle
              titleStyles
              title
              content
            }
          }
          ... on STRAPI_ComponentComponentsLinksSection {
            __typename
            title
            linksSectionLink {
              title
              link
            }
          }
          ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
        }
      }
    }
  }

`;